import React from 'react'

import HeaderPC from './pc'
import HeaderMobile from './mobile'
import { PATH, PATHNAME } from '../../constants'

import './style.scss'

const menuList = [
  {
    name: PATHNAME[PATH.home],
    to: PATH.home,
  },
  {
    name: PATHNAME[PATH.about],
    to: PATH.about,
    subMenus: [
      {
        name: PATHNAME[PATH.ourTeam],
        to: PATH.ourTeam,
      },
      {
        name: PATHNAME[PATH.careers],
        to: PATH.careers,
      },
    ],
  },
  {
    name: PATHNAME[PATH.services],
    to: PATH.services,
    subMenus: [
      {
        name: PATHNAME[PATH.socialMediaManagement],
        to: PATH.socialMediaManagement,
      },
      {
        name: PATHNAME[PATH.influencerMarketing],
        to: PATH.influencerMarketing,
      },
    ],
  },
  {
    name: PATHNAME[PATH.ourWork],
    to: PATH.ourWork,
  },
  {
    name: PATHNAME[PATH.getStarted],
    to: PATH.getStarted,
    key: 'btn',
  },
]

const Header = () => {
  return (
    <header className="nav-header">
      <h1 className="ghost-page-header">header</h1>
      {/* PC导航 */}
      <HeaderPC menus={menuList} />

      {/* 移动端 */}
      <HeaderMobile menus={menuList} />
    </header>
  )
}

export default Header
